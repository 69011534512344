import React from 'react';
import styles from "./styles.module.scss";
import {ReactComponent as House} from "../../assets/icons/house.svg";
import { useNavigate } from 'react-router-dom';
import Button from "../../components/Button/Button";


const NotFound: React.FC = () => {
    const navigate = useNavigate();

    return (
        <div className={styles.container}>
            <div className={styles.align}>
                <div className={styles.textError}>
                    <h1>Ops!</h1>
                    <span>Não encontramos o que você estava buscando.</span>
                    <div className={styles.button}>
                        <Button
                            width="171px"
                            height="49px"
                            theme="primary"
                            icon={<House/>}
                            onClick={() => navigate('/')}
                        >
                            Ir para o início
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotFound;
