import React from 'react';
import styles from "./styles.module.scss";

interface ButtonProps {
    children: string;
    width?: string;
    height?: string;
    fontSize?: string;
    borderRadius?: string;
    icon?: React.ReactNode;
    theme?: 'primary' | 'secondary' | 'danger' | 'close';
    onClick?: () => void;
    type?: 'button' | 'submit' | 'reset';
    disabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({
                                           children,
                                           width,
                                           height,
                                           fontSize,
                                           borderRadius,
                                           icon,
                                           theme = 'primary',
                                           onClick,
                                           type,
                                           disabled
                                       }) => {
    const buttonClass = `${styles.button} ${theme === 'primary' ? styles.primary : theme === 'secondary' ? styles.secondary : theme === 'danger' ? styles.danger : theme === 'close' ? styles.close : ''}`;

    return (
        <button className={buttonClass} style={{width, height, fontSize, borderRadius}} onClick={onClick} type={type}
                disabled={disabled}>
            {icon && <span className={styles.icon}>{icon}</span>}
            {children}
        </button>
    );
};

export default Button;
