import {IEnum} from "../../ts/interfaces/interface";

export enum ProductStatus {
    ENABLED = 1,
    DISABLED = 2,
    DELETED = 3
}

export const EnumProductStatus: IEnum[] = [
    { value: ProductStatus.ENABLED, text: 'Habilitado' },
    { value: ProductStatus.DISABLED, text: 'Desabilitado' },
    { value: ProductStatus.DELETED, text: 'Excluído' },
];