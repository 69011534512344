import React, {useState} from 'react';
import styles from "./styles.module.scss";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {IGetProductsParams, IGetProductsResponse} from "../../ts/interfaces/Products/interface";
import Select from "../../components/Select/Select";
import Button from "../../components/Button/Button";
import {ReactComponent as Download} from '../../assets/icons/download.svg';
import {getProducts, serializeArray} from "../../services/services";
import {useQuery} from 'react-query';
import Pagination from "../../components/Pagination/Pagination";
import {getToken} from "../../services/auth";
import {EnumProductStatus} from "../../helper/Products/Enum";
import BarLoader from "react-spinners/BarLoader";

const Products: React.FC = () => {
    document.title = 'Market Eduqi | Produtos'

    const [filter, setFilter] = useState<IGetProductsParams>({
        page: 1,
        limit: 50,
        name: '',
        sku: '',
        status: '',
        system_id: '',
    });

    const [filterParams, setFilterParams] = useState<IGetProductsParams>({
        page: 1,
        limit: 50,
        name: '',
        sku: '',
        status: '',
        system_id: '',
    });

    const {data, isLoading} = useQuery<IGetProductsResponse>(['getProducts', filter], () => getProducts(filter));

    const handleDownloadCSV = () => {
        let link = document.getElementById('download_csv');
        link?.click();
    };

    function handleFilter() {
        setFilter({
            ...filter,
            name: filterParams.name,
            sku: filterParams.sku,
            status: filterParams.status,
            system_id: filterParams.system_id,
            page: 1
        });
    }

    return (
        <div className={styles.container}>
            <h1>Produtos</h1>

            <Formik
                initialValues={{name: '', sku: ''}}
                onSubmit={() => {

                }}
            >
                <Form className={styles.searchProducts}>
                    <div className={styles.alignInput}>
                        <Field
                            name="name"
                            type="text"
                            placeholder="Nome"
                            value={filterParams.name}
                            className={styles.input}
                            onChange={(e: any) => setFilterParams({...filterParams, name: e.target.value})}
                            disabled={isLoading}
                        />
                        <ErrorMessage name="name" component="div" className={styles.error}/>
                        <Field
                            name="sku"
                            type="text"
                            placeholder="SKU"
                            value={filterParams.sku}
                            className={styles.input}
                            onChange={(e: any) => setFilterParams({...filterParams, sku: e.target.value})}
                            disabled={isLoading}
                        />
                        <ErrorMessage name="sku" component="div" className={styles.error}/>
                        <Select
                            value={filterParams.system_id ?? ''}
                            onChange={(e: any) => setFilterParams({...filterParams, system_id: e.target.value})}
                            disabled={isLoading}
                        >
                            <option value="">Loja</option>
                            <option value="1">Nova Concursos</option>
                            <option value="2">Editora Solução</option>
                            <option value="3">Apostilas Opção</option>
                        </Select>
                        <Select
                            value={filterParams.status ?? ''}
                            onChange={(e: any) => setFilterParams({...filterParams, status: e.target.value})}
                            disabled={isLoading}
                        >
                            <option value="">Status</option>
                            {EnumProductStatus.map((status) => {
                                return (
                                    <option value={status.value}>{status.text}</option>
                                );
                            })}
                        </Select>
                    </div>
                    <div className={styles.alignButtonSearch}>
                        <Button
                            type="submit"
                            width="115px"
                            height="39px"
                            theme="primary"
                            fontSize="14px"
                            onClick={() => handleFilter()}
                            disabled={isLoading}
                        >
                            Pesquisar
                        </Button>
                    </div>
                </Form>
            </Formik>

            <div className={styles.alignButtonCsvRegister}>
                <Button
                    width="115px"
                    height="39px"
                    theme="close"
                    icon={<Download/>}
                    fontSize="14px"
                    onClick={() => handleDownloadCSV()}
                    disabled={isLoading}
                >

                    CSV
                </Button>
            </div>
            <div className={styles.dNone}>
                <a id="download_csv" rel="noopener noreferrer"
                   href={process.env.REACT_APP_API_URL + "/v1/products/export?" + serializeArray(filter) + '&token=' + getToken()}
                   target="_blank" download></a>
            </div>
            <div className={styles.totalProducts}>
                <span>Total: {data && data.data ? data.data.total : '-'}</span>
            </div>
            <div className={styles.tableProducts}>
                {isLoading ?
                    <div className={styles.alignCenter}>
                        <span>Carregando</span>
                        <BarLoader
                            color={"#510071"}
                            loading={isLoading}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                    :
                    <>
                        {data && data.data.products.length > 0 ?
                            <table>
                                <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>SKU</th>
                                    <th>Preço</th>
                                    <th>Status</th>
                                    <th>Id Loja</th>
                                    <th>Id AnyMarket</th>
                                    <th>Categoria</th>
                                    <th>Marca</th>
                                    <th>Loja</th>
                                    <th>Sync</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {data.data.products.map((product) => (
                                    <tr key={product.id}>
                                        <td>{product.name}</td>
                                        <td>{product.sku}</td>
                                        <td>{product.price}</td>
                                        <td>{product.status}</td>
                                        <td>{product.internal_id}</td>
                                        <td>{product.external_id ?? ''}</td>
                                        <td>{product.category ?? ''}</td>
                                        <td>{product.brand ?? ''}</td>
                                        <td>{product.system ?? ''}</td>
                                        <td>{product.sync_anymarket}</td>
                                        <td></td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                            : (
                                <div className={styles.alignCenter}><span>Nenhum produto encontrado.</span></div>
                            )}
                    </>
                }
            </div>
            {data &&
                <Pagination
                    currentPage={data.data.currentPage}
                    lastPage={data.data.lastPage}
                    onPageChange={(payload: number) => setFilter({...filter, page: payload})}
                />
            }
        </div>
    );
};

export default Products;
