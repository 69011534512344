import React from 'react';
import styles from "./styles.module.scss";

const Dashboard: React.FC = () => {
    return (
        <div className={styles.container}>
            <h1>Início</h1>
        </div>
    );
};

export default Dashboard;
