import {IEnum} from "../../ts/interfaces/interface";

export enum OrderStatus {
    PENDING = 'PENDING',
    PAID = 'PAID_WAITING_SHIP',
    INVOICED = 'INVOICED',
    PAID_WAITING_DELIVERY = 'PAID_WAITING_DELIVERY',
    CONCLUDED = 'CONCLUDED',
    CANCELED = 'CANCELED',
}

export const EnumOrderStatus: IEnum[] = [
    { value: OrderStatus.PENDING, text: 'Pendente' },
    { value: OrderStatus.PAID, text: 'Pago' },
    { value: OrderStatus.INVOICED, text: 'Faturado' },
    { value: OrderStatus.PAID_WAITING_DELIVERY, text: 'Em transporte' },
    { value: OrderStatus.CONCLUDED, text: 'Concluído' },
    { value: OrderStatus.CANCELED, text: 'Cancelado' },
];