import React from 'react';
import styles from "./styles.module.scss";
import {ReactComponent as ArrowDown} from "../../assets/icons/arrow-down.svg";

interface SelectProps {
    children: React.ReactNode,
    width?: string,
    height?: string,
    disabled?: boolean
    value: string;
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const Select: React.FC<SelectProps> = ({children, width, height, disabled = false, value, onChange}) => {
    return (
        <div className={styles.selectWrapper}>
            <select className={styles.select}
                    style={{width, height}}
                    disabled={disabled}
                    value={value}
                    onChange={onChange}
            >
                {children}
            </select>
            <ArrowDown className={styles.icon}/>
        </div>
    );
};

export default Select;
