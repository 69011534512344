import React, { FC, useState, useEffect } from 'react';
import styles from "./styles.module.scss";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import { ReactComponent as Panel } from "../../assets/icons/painel.svg";
import { ReactComponent as Cart } from "../../assets/icons/cart.svg";
import { ReactComponent as Dollar } from "../../assets/icons/dollar.svg";
import { ReactComponent as UserIcon } from "../../assets/icons/user.svg";
import { ReactComponent as ProductIcon } from "../../assets/icons/product.svg";
import { ReactComponent as Exit } from "../../assets/icons/exit.svg";
import { ReactComponent as MenuIcon } from "../../assets/icons/menu.svg";
import Button from "../Button/Button";
import Dropdown from "../Dropdown/Dropdown";
import { Outlet, useNavigate } from 'react-router-dom';
import { useCookies } from "react-cookie";
import { useMutation } from "react-query";
import { getUser } from "../../services/services";
import { IGetUser } from "../../ts/interfaces/interface";

interface SidebarProps {}

const Sidebar: FC<SidebarProps> = () => {
    const [user, setUser] = useState<IGetUser | undefined>();
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies(["jwt_token_marketeduqi"]);
    const [isCollapsed, setIsCollapsed] = useState(false);

    const mutation = useMutation<IGetUser, Error>(() => getUser(), {
        onSuccess: (data) => {
            setUser(data);
        },
        onError: (error) => {
            console.error('Erro ao buscar nome do usuário:', error);
        }
    });

    useEffect(() => {
        mutation.mutate();
    }, []);

    function logout() {
        setCookie("jwt_token_marketeduqi", "", {
            domain: window.location.hostname,
            path: "/",
            maxAge: -1
        });
        localStorage.removeItem('jwt_token_marketeduqi');
        navigate("/");
    }

    return (
        <>
            <div className={`${styles.sidebar} ${isCollapsed ? styles.collapsed : ''}`}>
                <div className={styles.sidebarHeader}>
                    <a onClick={() => navigate('/')}><Logo className={styles.svgLogo}/></a>
                    <p>Olá {user?.data.name}</p>
                    <Button
                        width="100px"
                        height="25px"
                        theme="close"
                        fontSize="12px"
                        borderRadius="6px"
                        icon={<Exit />}
                        onClick={logout}
                    >
                        Sair
                    </Button>
                    <button className={styles.menuButton} onClick={() => setIsCollapsed(!isCollapsed)}>
                        <MenuIcon/>
                    </button>
                </div>

                <ul className={styles.sidebarMenu}>
                    <div className={styles.controlPanel} onClick={() => navigate('/')}>
                        <i><Panel /></i>
                        <span>Painel de controle</span>
                    </div>
                    <Dropdown className={styles.DropdownClose} label="Produtos" icon={<ProductIcon />}>
                        <li onClick={() => navigate('/products')}>Listar</li>
                    </Dropdown>
                    <Dropdown className={styles.DropdownClose} label="Pedidos" icon={<Cart/>}>
                        <li onClick={() => navigate('/orders')}>Listar</li>
                    </Dropdown>
                    {/*<Dropdown className={styles.DropdownClose} label="Financeiro" icon={<Dollar />} />*/}
                    {/*<Dropdown className={styles.DropdownClose} label="Minha conta" icon={<UserIcon />} />*/}
                </ul>
            </div>
            <div className={`${styles.mainContainer} ${isCollapsed ? styles.expanded : ''}`}>
                <Outlet />
            </div>
        </>
    );
};

export default Sidebar;
