import React from 'react';
import {Modal, ModalBody} from 'reactstrap';
import styles from './styles.module.scss';
import {IOrderError} from "../../ts/interfaces/Orders/interface";

interface ModalConfirmProps {
    toggle: () => void;
    open: boolean;
    orderError: IOrderError;
}

const ModalOrderError: React.FC<ModalConfirmProps> = ({toggle, open, orderError}) => {
    return (
        <Modal isOpen={open} toggle={toggle} className={styles.modalContent} centered>
            <ModalBody className={styles.modalBody}>
                <button
                    className={styles.closeButton}
                    onClick={toggle}
                    aria-label="Close modal"
                >
                    &times;
                </button>
                <h2 className={styles.title}>Erro na {orderError.type}</h2>
                <div className={styles.errorContent}>
                    {orderError.class && <span><strong>Arquivo:</strong> {orderError.class}</span>}
                    <span><strong>Mensagem:</strong> {orderError.message}</span>
                    {orderError.log && (
                        <div>
                            <span><strong>Log:</strong></span>
                            <p>{orderError.log}</p>
                        </div>
                    )}
                </div>
            </ModalBody>
        </Modal>
    );
};

export default ModalOrderError;
